<template>
  <div class="column">
    <div class="top_back">
      <img
        class="back"
        src="../../../assets/img/home/home/PES/icon_pes_back.png"
        @click="back"
      />
      <div class="title">幼儿报告</div>
      <div class="oldEnter" @click="clickOldEnter">旧版报告入口</div>
    </div>
    <div class="row_normal text_center">
      <div class="content">
        <div class="row_normal">
          <div class="pes-select select-item">
            <el-select
              v-model="valueClass"
              placeholder="选择班级"
              @change="bindStu"
            >
              <el-option
                v-for="item in options3"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="flex"></div>
          <div class="one_share" @click="goShareAll">一键分享</div>
        </div>
        <el-table :data="tableData" style="width: 100%; margin-top: 30px">
          <el-table-column type="index" label="编号" align="center">
          </el-table-column>
          <el-table-column prop="name" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="sex" label="性别" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.sex == 1" class="table_btns">男</div>
              <div v-else class="table_btns">女</div>
            </template>
          </el-table-column>
          <el-table-column prop="age" label="年龄" align="center">
          </el-table-column>
          <el-table-column prop="parent" label="家长" align="center">
          </el-table-column>
          <el-table-column prop="linkMobile" label="联系方式" align="center">
          </el-table-column>
          <el-table-column prop="isLook" label="是否查阅" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.isLook == 0" class="table_btns_no">否</div>
              <div v-else class="table_btns">是</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="isComplete"
            label="是否已上传数据"
            align="center"
          >
            <template slot-scope="scope">
              <div
                v-if="scope.row.isComplete == 0"
                class="table_btns_no"
                @click="clickUploadData(scope.row)"
              >
                否
              </div>
              <div v-else class="table_btns">是</div>
            </template>
          </el-table-column>
          <el-table-column prop="schoolname" label="报告预览" align="center">
            <template slot-scope="scope">
              <img
                class="code_img"
                :src="scope.row.codeUrl"
                @click="goReportInfo(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
        <comPagination
          :params="params"
          @changePage="changePage1"
        ></comPagination>
      </div>
    </div>
    <div class="h100"></div>

    <el-dialog :visible.sync="dialogVis" center @click="cancel">
      <div class="dialog_title">{{ currentChildren.name }}</div>
      <div class="grid_lay">
        <div v-for="(item, index) in testLabel" :key="index">
          <div class="label_lay">
            <el-input
              class="dialog_inpu"
              maxlength="20"
              v-model="singleTestData[item.testNo.toLowerCase()]"
              :placeholder="
                item.testNo.toLowerCase() == 'c' ? '左眼/右眼' : '请输入'
              "
            ></el-input>
            <div class="dialog_text">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin: 0.2rem auto 0 auto">
        <el-button class="btn active" @click="post">提交</el-button>
        <el-button class="btn" @click="cancel">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="imgShow" center @click="cancel1"  width="300px">
      <div class="column_center">
        <img class="big_img" :src="currentCodeUrl" />
        <a class="down_code" :href="currentCodeUrl">下载二维码</a>
      </div>
    </el-dialog>
  </div>
</template>
  <script>
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "monthInfo",
  components: {
    comPagination,
  },
  data() {
    return {
      valueClass: "",
      options3: [],
      tableData: [],
      dialogVis: false,
      dialogHeight: "",
      key: "",
      gradeId: "",
      params: {
        // 评分分页
        page: 1,
        size: 10,
        total: 1,
      },
      testLabel: [],
      singleTestData: {},
      currentChildren: {},
      currentCodeUrl: "",
      imgShow: false,
      shareUrl: "",
    };
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    cancel() {
      this.dialogVis = false;
    },
    cancel1() {
      this.imgShow = false;
    },
    lookBigImg(url) {
      this.currentCodeUrl = url;
      this.imgShow = true;
    },
    // 一键分享
    goShareAll() {
      this.currentCodeUrl = this.shareUrl;
      this.imgShow = true;
    },
    goReportInfo(item) {
      this.$router.push({
        path: "/reportInfo?type=2&key=" + item.key,
      });
    },
    post() {
      console.log(this.singleTestData);
      for (var i = 0; i < this.testLabel.length; i++) {
        if (!this.singleTestData[this.testLabel[i].testNo.toLowerCase()]) {
          this.$notice({
            message: this.testLabel[i].title + "不能为空",
          }).isShow();
          return false;
        } else {
          if (this.testLabel[i].testNo.toLowerCase() == "c") {
            let eye =
              this.singleTestData[this.testLabel[i].testNo.toLowerCase()];
            if (eye.indexOf("/") != -1) {
              let a = eye.split("/")[0];
              let b = eye.split("/")[1];
              if (
                Number(a) < this.testLabel[i].testingMin ||
                Number(a) > this.testLabel[i].testingMax
              ) {
                this.singleTestData[this.testLabel[i].testNo.toLowerCase()] =
                  "";
                this.$notice({
                  message:
                    this.testLabel[i].title +
                    "的范围为" +
                    this.testLabel[i].testingMin +
                    "~" +
                    this.testLabel[i].testingMax,
                }).isShow();
                return false;
              }
              if (
                Number(b) < this.testLabel[i].testingMin ||
                Number(b) > this.testLabel[i].testingMax
              ) {
                this.singleTestData[this.testLabel[i].testNo.toLowerCase()] =
                  "";
                this.$notice({
                  message:
                    this.testLabel[i].title +
                    "的范围为" +
                    this.testLabel[i].testingMin +
                    "~" +
                    this.testLabel[i].testingMax,
                }).isShow();
                return false;
              }
            } else {
              this.singleTestData[this.testLabel[i].testNo.toLowerCase()] = "";
              this.$notice({
                message: "视力输入格式为 左眼/右眼",
              }).isShow();
              return false;
            }
          } else {
            if (
              Number(
                this.singleTestData[this.testLabel[i].testNo.toLowerCase()]
              )
            ) {
              var value = Number(
                this.singleTestData[this.testLabel[i].testNo.toLowerCase()]
              );
              if (
                value < this.testLabel[i].testingMin ||
                value > this.testLabel[i].testingMax
              ) {
                this.singleTestData[this.testLabel[i].testNo.toLowerCase()] =
                  "";
                this.$notice({
                  message:
                    this.testLabel[i].title +
                    "的范围为" +
                    this.testLabel[i].testingMin +
                    "~" +
                    this.testLabel[i].testingMax,
                }).isShow();
                return false;
              }
            } else {
              this.singleTestData[this.testLabel[i].testNo.toLowerCase()] = "";
              return false;
            }
          }
        }
      }
      this.dialogVis = false;
      this.postSingleTestData();
    },
    clickOldEnter() {
      this.$router.push({
        path: "/PESHome",
      });
    },
    bindStu(val) {
      console.log(val);
      this.gradeId = val.id;
      this.valueClass = val.name;
      this.params.page = 1;
      this.params.total = 1;
      this.getList();
    },
    changePage1(val) {
      this.params.page = val;
      this.getList();
    },
    getList() {
      let params = {
        gradeId: this.gradeId,
        key: this.key,
        pageNo: this.params.page,
      };
      this.api.pes.testNewRecordInfo(params).then((res) => {
        if (res.code == 20000) {
          this.tableData = res.data.records;
          this.params.total = res.data.total;
        }
      });
    },
    getGradeList() {
      let params = {
        teacherId: this.$store.state.userInfo.teacherId,
      };
      this.api.admin.findGradesOfid(params).then((res) => {
        if (res.code == 20000) {
          this.options3 = res.data;
        }
      });
    },
    // 获取分享二维码
    getShareImageUrl() {
      let params = {
        key: this.key,
      };
      this.api.pes.getShareImageUrl(params).then((res) => {
        if (res.code == 20000) {
          this.shareUrl = res.data.codeUrl;
        }
      });
    },
    // 获取个人测试项
    getSingleTest() {
      let params = {};
      this.api.pes.getNormalTestLabel(params).then((res) => {
        if (res.code == 20000) {
          this.testLabel = res.data;
        }
      });
    },
    // 获取个人测试数据
    getSingleTestData(id) {
      let params = {
        key: id,
      };
      this.api.pes.getSingleTestData(params).then((res) => {
        if (res.code == 20000) {
          this.singleTestData = res.data;
        }
      });
    },
    // 提交个人测试数据
    postSingleTestData() {
      for (var i = 0; i < this.testLabel.length; i++) {
        let value = this.singleTestData[this.testLabel[i].testNo.toLowerCase()];
        this.singleTestData[this.testLabel[i].testNo.toLowerCase()] = {};
        this.singleTestData[this.testLabel[i].testNo.toLowerCase()].value =
          value;
        this.singleTestData[this.testLabel[i].testNo.toLowerCase()].sure = true;
        this.singleTestData[this.testLabel[i].testNo.toLowerCase()].remark = "";
      }
      console.log(this.singleTestData);
      let params = this.singleTestData;
      this.api.pes.saveSingleTestData(params).then((res) => {
        if (res.code == 20000) {
          this.dialogVis = false;
          this.currentChildren.isComplete = 1;
        }
      });
    },
    // 点击上传数据
    clickUploadData(item) {
      console.log(item);
      this.currentChildren = item;
      this.dialogVis = true;
      this.getSingleTest();
      this.getSingleTestData(item.key);
    },
    getSchoolGradeList() {
      var that = this;
      this.api.school
        .getSchoolGradeList(this.$store.state.userInfo.schoolId)
        .then((res) => {
          if (res.flag) {
            for (var x in res.data.大班) {
              console.log(x);
              that.options3.push(res.data.大班[x]);
            }
            for (var y in res.data.中班) {
              that.options3.push(res.data.中班[y]);
            }
            for (var z in res.data.小班) {
              that.options3.push(res.data.小班[z]);
            }
          }
        });
    },
  },
  mounted() {
    this.key = decodeURIComponent(this.$route.query.key);
    console.log(this.key);
    if (this.$store.state.userInfo.teacherId) {
      this.getGradeList();
    } else {
      this.getSchoolGradeList();
    }
    this.getList();
    this.getShareImageUrl();
  },
  computed: {},
};
</script>
    <style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
}
.top_back {
  position: relative;
  height: 42px;
  background: #333333;
  .back {
    position: absolute;
    top: 13px;
    left: 140px;
    width: 42px;
    height: 15px;
    cursor: pointer;
  }
  .title {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .oldEnter {
    position: absolute;
    top: 7px;
    right: 140px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cf5d5d;
    cursor: pointer;
  }
}
.row_normal {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.month_lay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #333333;
}
.w1000 {
  width: 1200px;
  height: 40px;
  overflow-x: scroll;
}
.swip-item {
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
}
.month_normal {
  width: 70px;
  height: 30px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.text_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.flex {
  flex: 1;
}
.content {
  width: 1200px;
  background: #fff;
  margin-top: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 50px;
}
.pes-select {
  display: flex;
}
.select-item {
  width: 130px;
  height: 38px;
  margin-left: 32px;

  /deep/ .el-input__inner {
    font-size: 18px;
  }
}
.pl_input {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b02424;
  margin-left: 20px;
  margin-right: 30px;
}
.h100 {
  height: 100px;
}
.riseConter {
  padding: 30px;

  p {
    color: #333;
    line-height: 45px;
  }
}
.hint {
  color: red !important;
}

.hintMailbox {
  font-size: 14px;
  font-weight: 400;
  color: #cb1b32;
  text-align: center;
}

.btn {
  width: 100px;
  height: 38px;
  //background: none;
  //border:none;
  border: 1px solid #ba3037;
  border-radius: 6px;
  background: #fff;
  color: #ba3037;
  font-size: 18px;
  margin: 0 25px;
}

.active {
  background: linear-gradient(232deg, #b93037, #ef525b);
  box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
  color: #fff;
  border: 1px solid transparent;
}

/deep/ .el-dialog__header {
  padding: 0;
  position: relative;
}

/deep/ .el-dialog__headerbtn {
  top: 0.15rem;
  opacity: 0;
}
.column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dialog_inpu {
  width: 120px;

  /deep/ .el-input__inner {
    font-size: 18px;
    text-align: center;
  }
}
.dialog_text {
  font-size: 20px;
  color: #333;
  margin-top: 3px;
}
.mt30 {
  margin-top: 30px;
}
.bottom {
  width: 750px;
  height: 3000px;
}
.table_btns {
  color: #333333;
  font-size: 18px;
}
.table_btns_no {
  color: #e00500;
  font-size: 18px;
  cursor: pointer;
}
.grid_lay {
  width: 940px;
  display: grid;
  grid-template-columns: repeat(6, 140px);
  /* justify-content: center; */
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  .label_lay {
    width: 140px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
}
.dialog_title {
  width: 100%;
  font-size: 20px;
  color: #333;
  margin-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.one_share {
  font-size: 26px;
  color: #333;
  margin-right: 30px;
  cursor: pointer;
}
.code_img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.big_img {
  width: 300px;
  height: 300px;
}
.down_code {
  cursor: pointer;
  font-size: 20px;
  color: #333333;
  margin-top: 10px;
}
</style>